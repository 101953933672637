import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, Menu } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"ScrapDivers"} />
		<Helmet>
			<title>
				Scrap Divers on Consoles
			</title>
			<meta name={"description"} content={"Scrap Divers"} />
			<meta property={"og:title"} content={"Scrap Divers on Consoles"} />
			<meta property={"og:description"} content={"Scrap Divers"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/2x1%20copy.jpg?v=2025-03-18T10:40:06.850Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/256.png?v=2025-03-18T13:59:20.610Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Menuu />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/background%20copy.png?v=2025-03-18T10:42:22.193Z) center/cover" md-background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/New-Art-16x9-4k-cover.png?v=2022-09-09T00:10:26.928Z) center/cover no-repeat scroll">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="460px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					margin="0"
					font="--base"
					letter-spacing="1px"
					text-transform="uppercase"
				>
					BY{" "}
					<Link href="https://gearheadgames.com/" target="_blank" color="#000000">
						Gearhead Games ApS{"\n\n"}
					</Link>
				</Text>
				<Text as="h1" margin="0 0 16px 0" font="--headline2" lg-font="--headline2">
					Scrap Divers
				</Text>
				<Text as="p" margin="16px 0" font="--base" max-width="400px">
					Dive into an incredibly fast-paced and beautifully crafted retro runner! Control your robot with just a finger to dodge sawblades, flames, and many other dangerous obstacles while flying through an infinite tunnel. Are you ready for the drop? Parachute not included.
					<br />
					{"\n\n"}
				</Text>
				<Link
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					href="https://store.playstation.com/product/EP5077-PPSA28174_00-0356998192881191"
					target="_blank"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					border-radius="2px"
					text-align="center"
					width="100%"
				>
					Playstation
				</Link>
				<Link
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					href="https://www.xbox.com/games/store/scrap-divers/9nh0q2bmgfrh"
					target="_blank"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					border-radius="2px"
					text-align="center"
					width="100%"
				>
					Xbox One
				</Link>
				<Link
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					href="https://www.nintendo.com/us/store/products/scrap-divers-switch/?srsltid=AfmBOorr6LwMtFjY28xqFOxBsRq7XO1tA7F9ybYktXTVTpA8h9_B2EZH"
					target="_blank"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					border-radius="2px"
					text-align="center"
					width="100%"
				>
					Nintendo Switch (America)
				</Link>
				<Link
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					target="_blank"
					border-radius="2px"
					text-align="center"
					href="https://www.nintendo.com/en-gb/Games/Nintendo-Switch-download-software/Scrap-Divers-2761087.html?srsltid=AfmBOooyx7A5tg18lHDE5PN6R8IwuZm5NnTFCoSmLOB5sr2qQObLSD7i"
					width="100%"
				>
					Nintendo Switch (Europe)
				</Link>
			</Box>
		</Section>
		<Section text-align="center" padding="10px 0 10px 0" sm-padding="40px 0">
			<Box display="flex" margin="10px 0 10px 0" justify-content="space-around" sm-flex-direction="column" />
		</Section>
		<Section text-align="center" padding="10px 0 10px 0" sm-padding="40px 0" />
		<Components.QuarklycommunityKitYouTube url="https://www.youtube.com/watch?v=TFDlztkANmY" />
		<Components.Htmlform />
		<Section background-color="--dark" text-align="center" padding="32px 0" sm-background="#0f1923">
			<Menu
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
				display="flex"
				justify-content="center"
				font="--lead"
			>
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
			</Menu>
			<Link
				href="mailto:hello@company.com"
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
			/>
		</Section>
		<Link href="#" color="#000000" />
	</Theme>;
});